<template>
  <div class="page">
    <base-left-nav-bar :activeIndex="activeIndex"></base-left-nav-bar>
    <div class="main">
      <base-top-nav-bar></base-top-nav-bar>
      <div class="mian-wrapper">
        <base-page-header>
          <span slot="r"
            ><slot><a href="/machineList">设备管理 — </a></slot></span
          >
          <span slot="m"
            ><slot><a href="/machineList">设备列表 </a></slot></span
          >
          <span slot="l"><slot>> 修改基本信息 </slot></span>
        </base-page-header>
        <div class="main-manager">
          <el-form
            :model="deviceForm"
            :rules="rules"
            ref="deviceForm"
            label-width="150px"
            class="deviceForm"
          >
            <el-form-item label="序号：">
              <p>{{ deviceForm.deviceid }}</p>
            </el-form-item>
            <el-form-item label="设备名：">
              <p>{{ deviceForm.device_name }}</p>
            </el-form-item>
            <el-form-item label="设备备注名：" prop="nickname">
              <el-input
                v-model="deviceForm.nickname"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <el-form-item label="productkey：">
              <p>{{ deviceForm.productkey }}</p>
            </el-form-item>
            <el-form-item label="设备密钥：">
              <p>{{ deviceForm.device_secret }}</p>
            </el-form-item>
            <el-form-item label="物联网号：">
              <p>{{ deviceForm.iot_id }}</p>
            </el-form-item>
            <el-form-item label="所属客户公司：">
              <el-select
                v-model="deviceForm.companyid"
                placeholder="请选择"
                @change="companyChange"
              >
                <el-option
                  v-for="item in companyOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="所属油田：" v-show="flag">
              <el-select
                v-model="deviceForm.oilfieldid"
                placeholder="请选择指定油田"
              >
                <el-option
                  v-for="item in oilfieldOpitons"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="冲程：">
              <el-input
                v-model.number="deviceForm.device_stroke"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <el-form-item label="泵径：">
              <el-input
                v-model.number="deviceForm.device_diameter"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <el-form-item label="通知手机号：" prop="notify_phone">
              <el-input
                v-model="deviceForm.notify_phone"
                placeholder="多个手机号请用 | 隔开"
              ></el-input>
            </el-form-item>
            <!-- <el-form-item label="设备负责人：" v-show="flag">
              <el-input
                v-model="deviceForm.manager_info"
                placeholder="请输入"
                @change="userGet"
              ></el-input>
              <span class="tips">注：输入手机号验证人名</span>
            </el-form-item> -->
            <el-form-item label="创建时间：">
              <p>{{ deviceForm.date }}</p>
            </el-form-item>
            <el-form-item>
              <el-button class="button" @click="resetForm">重置</el-button>
              <el-button
                class="submit"
                type="primary"
                @click="submitForm('deviceForm')"
                >确认</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseLeftNavBar from 'components/base-nav-bar/base-left-nav-bar.vue'
import BaseTopNavBar from 'components/base-nav-bar/base-top-nav-bar.vue'
import BasePageHeader from '../../components/base-page-header/base-page-header.vue'

import MachineJS from 'network/machine/machine.js'

export default {
  components: {
    BaseLeftNavBar,
    BaseTopNavBar,
    BasePageHeader
  },
  data() {
    return {
      activeIndex: 3,
      params: {},
      deviceForm: {
        device_name: '',
        nickname: '',
        companyid: '',
        oilfieldid: '',
        notify_phone: '',
        device_stroke: null,//冲程
        device_diameter:null,//泵径

      },
      flag: true,
      rules: {
        name: [{ required: true, message: '请输入设备名称', trigger: 'blur' }],
        nickname: [
          { required: true, message: '请输入设备备注名', trigger: 'blur' }
        ]
      },
      companyOptions: [],
      oilfieldOpitons: []
    }
  },
  watch: {},
  methods: {
    /**
     * 事件监听
     */
    // 修改并提交用户表单
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        // 判断
        if (this.deviceForm.notify_phone != '') {
          // 判断
          const flag = this.regNotifyPhone(this.deviceForm.notify_phone)
          console.log(flag)
          if (flag) {
            this.deviceUpdate()
            return
          }
          this.$message({
            showClose: true,
            duration: 1500,
            message: '通知手机号格式不规范',
            type: 'warning'
          })
          return
        }
        this.deviceUpdate()
      })
    },
    resetForm() {
      this.deviceGet()
      this.getCompanyList()
    },
    companyChange(v) {
      this.deviceForm.oilfieldid = ''
      if (v != 0) {
        this.flag = true
        this.getOilfieldList(v)
      } else {
        // 设置油田关系置为0或空
        this.flag = false
        this.deviceForm.oilfieldid = 0
        this.deviceForm.manager = ''
      }
    },
    /**
     * 网络请求
     */
    deviceUpdate() {
      this.deviceForm.session_id = this.params.session_id
      MachineJS.deviceUpdate(this.deviceForm).then((res) => {
        if (res.code == 0) {
          this.$message.success(res.msg)
          setTimeout(() => {
            this.$router.replace('/machineList')
          }, 500)
        } else {
          this.$message.closeAll()
          this.$message({
            showClose: true,
            duration: 1500,
            message: res.msg,
            type: 'warning'
          })
        }
      })
    },
    // 获取设备
    deviceGet() {
      this.params.session_id = this.$store.state.loginInfo.session_id
      this.params.deviceid = this.$route.query.deviceid
      
      MachineJS.deviceGet(this.params).then((res) => {
        if (res.code == 0) {
          this.deviceForm = res.device_data
          if (this.deviceForm.companyid == 0) {
            this.deviceForm.companyid = ''
          } else {
            this.getOilfieldList(this.deviceForm.companyid)
          }
          if (this.deviceForm.oilfieldid == 0) {
            this.deviceForm.oilfieldid = ''
          }
          
        } else {
          this.$message.closeAll()
          this.$message({
            showClose: true,
            duration: 1500,
            message: res.msg,
            type: 'warning'
          })
        }
      })
    },
    // 获取公司列表
    getCompanyList() {
      const param = {
        session_id: this.$store.state.loginInfo.session_id,
        count: 99,
        page: 0
      }
      MachineJS.companyList(param).then((res) => {
        if (res.code == 0) {
          const arr = []
          arr.push({ value: 0, label: '无' })
          for (const i of res.company_list) {
            const obj = { value: '', label: '' }
            obj.value = i.companyid
            obj.label = i.name
            arr.push(obj)
          }

          this.companyOptions = arr
        } else {
          this.$message.closeAll()
          this.$message({
            showClose: true,
            duration: 1500,
            message: res.msg,
            type: 'warning'
          })
        }
      })
    },
    // 获取油田列表
    getOilfieldList(v) {
      const param = {
        session_id: this.$store.state.loginInfo.session_id,
        companyid: v,
        count: 99,
        page: 0
      }
      MachineJS.oilfieldList(param).then((res) => {
        if (res.code == 0) {
          this.oilfieldOpitons = []
          const arr = []
          arr.push({ value: 0, label: '无' })
          for (const i of res.oilfield_list) {
            const obj = { value: '', label: '' }
            obj.value = i.oilfieldid
            obj.label = i.name
            arr.push(obj)
          }
          this.oilfieldOpitons = arr
        } else {
          this.$message.closeAll()
          this.$message({
            showClose: true,
            duration: 1500,
            message: res.msg,
            type: 'warning'
          })
        }
      })
    },
    /**
     * 其它方法
     */
    // 通知手机号验证
    regNotifyPhone(v) {
      const reg = /^[0-9]{11}$/
      if (v.includes('|')) {
        const arr = v.split('|')
        return arr.every((v) => {
          return reg.test(v)
        })
      }
      if (!reg.test(v)) {
        return false
      }
      return true
    }
  },
  mounted() {
    this.deviceGet()
    this.getCompanyList()
  },
  created() {
    this.$store.commit(
      'setLogin',
      JSON.parse(sessionStorage.getItem('loginInfo'))
    )
  },
  beforeDestroy() {
    this.$message.closeAll()
  }
}
</script>

<style lang="less" scoped>
.page {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  min-width: 1300px;
  min-height: 100vh;
  // min-height: 1080px;
}
.main {
  width: 1698px;
  height: auto;
  background: #ebecee;
}
.mian-wrapper {
  padding: 28px; // 边距
  width: 1698px;
  height: 879px;
  .main-manager {
    width: 1642px;
    height: 82.5vh;
    background: #fff;
    .deviceForm {
      padding: 45px 0 0 72px;
      width: auto;
      height: auto;
      .tips {
        margin-left: 17px;
        width: auto;
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: rgba(1, 158, 229, 0.88);
      }
      .reset {
        margin-left: 90px;
        border-radius: 0;
      }
      .submit {
        margin-left: 20px;
        border-radius: 0;
      }
    }
  }

  ::v-deep {
    .el-input__inner {
      border-radius: 0px;
    }
    .el-form-item:not(:last-child) {
      margin-bottom: 13px;
      border-radius: 0px;
      .el-form-item__label {
        font-size: 18px;
        font-family: Source Han Sans CN;
      }
      .el-input {
        width: 332px;
        height: 32px;
      }
    }
  }
}
</style>
